import React from 'react';

import { Layout } from '../components/shared/Layout';
import { SEO } from '../components/shared/seo';

const AboutPage: React.FC = () => {
  return (
    <Layout padded={true}>
      <SEO title="Maak Het Mee!" />
      <section className="py-8">
        <div className="container">
          <div className="py-8" style={{ borderTop: '3px solid black' }}>
            <h1 className="font-heading font-bold text-4xl">Maak Het Mee!</h1>
          </div>

          <div>
            <h3 className="font-bold my-4 text-xl">DONEREN</h3>
            <p className="mb-8 leading-8 text-lg">
              De Haagse Makers Week wordt gepresenteerd door Haagse Makers en Urbanlink, met een gift wordt de
              organisatie van dit event mogelijk.{' '}
            </p>
            <h3 className="font-bold my-4 text-xl">SPONSOR</h3>
            <p className="mb-8 leading-8 text-lg">
              Sponsoren maken de Haagse Makers Week een realiteit. We nodigen je uit om mee te doen door dit community
              event te ondersteunen en het makerschap en de discussie over maken in de regio tot een hoger plan te
              tillen. Email ons op hoemaaktuhet@haagsemakers.nl
            </p>
            <h3 className="font-bold my-4 text-xl">VOLUNTEER</h3>
            <p className="mb-8 leading-8 text-lg">
              De Haagse Makers Week wordt georganiseerd met de hulp van een team vrijwilligers met diverse achtergronden
              en expertises. Email ons om mee te doen op hoemaaktuhet@haagsemakers.nl.
            </p>
            <h3 className="font-bold my-4 text-xl">GET IN TOUCH</h3>
            <p className="mb-8 leading-8 text-lg">
              Meld je aan op het <a href="https://community.haagsemakers.nl">Haagse Makers Community Forum</a> om op de
              hoogte te blijven van de laatste ontwikkelingen. Heb je nog andere vragen? Mail dan naar
              hoemaaktuhet@haagsemakers.nl.
            </p>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default AboutPage;
